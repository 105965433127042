
import { Vue, Options } from 'vue-class-component';
import debounce from 'lodash-es/debounce';
import Calendar from 'dayjs/plugin/calendar';
import axios, { CancelTokenSource } from 'axios';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import {
  BaseIcon,
  BasePagination,
  BaseTextInput,
  DataTable,
  BasePopover,
  SpecialityButton,
  PopoverLink,
  BaseButton
} from '@/lib/components';
import RejectReasonModal from '@/lib/components/Modals/RejectReasonModal.vue';
import { Clinic, Consult, Patient, RejectReasonValue } from '@/models';
import {
  CodeableConceptService,
  EncounterService,
  EpisodeOfCareService
} from '@/services/api';
import AssignConsultModal from '@/views/worklists/AssignConsultModal.vue';
import {
  getDobFromISOString,
  getNhsNumberFromPatient
} from '@/helpers/patient.helper';
import PopoverButton from '@/lib/components/Popover/PopoverButton.vue';
import {
  sexOptions,
  genderOptions,
  codeableConceptCategories
} from '@/constants';
import { TranslateResult } from 'vue-i18n/index';
import { useProgressStore } from '@/stores/progress.store';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';
import { CodeableConcept, IErrors } from '@/lib';
import OphthalmologyConsultAggregateService, {
  RequestParams
} from '@/services/aggregate/ophthalmology-consult.aggregate.service';
import OphthalmologyConsultViewModel from '@/models/ophthalmology-consult.view.model';
import { EpisodeOfCareResponseData } from '@/models/episode-of-care/episode-of-care.model';
import { ScheduleValue } from '@/models/episode-of-care/encounter.model';
import { findCodeableConceptByCode } from '@/helpers/codeableConcepts.helper';

dayjs.extend(localizedFormat);

@Options({
  components: {
    PopoverButton,
    RejectReasonModal,
    BaseIcon,
    AssignConsultModal,
    BaseButton,
    PopoverLink,
    SpecialityButton,
    BasePopover,
    BasePagination,
    BaseTextInput,
    DataTable
  }
})
export default class WorklistPage extends Vue {
  loading = true;
  scheduleErrors: Record<string, string[]> = {};
  progressStore = useProgressStore();
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();

  rows: Partial<Consult>[] = [];
  perPage = 0;
  total = 0;
  search = '';
  filtered = false;
  encounterService = new EncounterService();

  ophthalmologyConsultService = new OphthalmologyConsultAggregateService(
    new EpisodeOfCareService(),
    new CodeableConceptService(),
    new EncounterService()
  );

  ophthalmologyConsults: OphthalmologyConsultViewModel[] = [];
  statuses: CodeableConcept[] = [];

  request: CancelTokenSource | null = null;
  unWatchRoute = null;
  selectedConsultId = '';
  enableAssignConsultModal = false;

  enableRejectReasonModal = false;
  sexOptions = sexOptions();

  genderOptions = genderOptions();
  // Reject
  currentConsult: Partial<Consult> | null = null;
  selectedPatient: Patient | null = null;

  rejectReasonError: string | null = null;

  get page() {
    return Number(this.$route.query.page) || 1;
  }

  get sort() {
    return this.$route.query.sort || 'updated_at';
  }

  get organisationId() {
    return this.$route.params.organisationId;
  }

  created() {
    this.progressStore.startProgress();
    dayjs.extend(Calendar);
  }

  unmounted() {
    this.progressStore.removeProgress();
    if (this.request) {
      this.request.cancel();
    }
    if (this.unWatchRoute) {
      this.unWatchRoute();
    }
  }

  async mounted() {
    this.search = String(this.$route.query.search || '');
    await this.fetchConsults();

    await this.fetchStatuses();

    this.unWatchRoute = this.$watch('$route', async (to, from) => {
      if (from.path === to.path && from.query !== to.query) {
        await this.fetchConsults();
        window.scroll({
          top: 0,
          behavior: 'smooth'
        });
      }
    });

    // Re-fetch consults when switching organisation
    this.$watch('organisationId', async () => {
      this.search = '';
      if (Object.keys(this.$route.query).length) {
        await this.$router.replace({ path: this.$route.path });
      }
      await this.fetchConsults();
    });
  }

  extractEpisodeOfCareFromConsult(
    id: string
  ): EpisodeOfCareResponseData | undefined {
    return this.ophthalmologyConsults.find(
      (consult: OphthalmologyConsultViewModel) =>
        id === consult.episodeOfCare.id
    )?.episodeOfCare;
  }

  buildClinicTypeOptions(clinic: Clinic) {
    return {
      value: clinic.id,
      label: clinic.name
    };
  }

  async fetchStatuses() {
    try {
      this.statuses =
        await this.ophthalmologyConsultService.fetchCodeableConceptsByCategory(
          codeableConceptCategories.encounterStatus
        );
    } catch (error) {
      if (!axios.isCancel(error)) {
        this.progressStore.setError();
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.consult.fetch-error')
        });
      }
    }
  }

  async fetchConsults() {
    this.loading = true;

    this.request = axios.CancelToken.source();
    const requestConfig: RequestParams = {
      include: 'ophthalmologyEpisodeOfCareDetail,media,patient',
      ...(this.search ? { 'filter[search]': this.search } : {}),
      ...{
        'filter[status]': 'Needs Scheduling',
        'filter[provider]': this.organisationId
      }
    };

    try {
      const response = await this.ophthalmologyConsultService.fetchAll(
        this.organisationId,
        {
          extraParams: requestConfig,
          paginationOptions: {
            page: this.page,
            sort: this.sort,
            perPage: 10
          },
          cancelToken: { ...this.request.token }
        }
      );
      this.request = null;
      this.ophthalmologyConsults = response.data;

      this.rows = response.data.map(
        (consult: OphthalmologyConsultViewModel) => {
          const result: Partial<Consult> = {
            id: consult.episodeOfCare.id,
            status: consult.status,
            created_at: consult.createdDate,
            completed_at: consult.hasConsultDate ? consult.consultDate : null,
            consult_type: consult.consultType,
            patient: {
              ...consult.patient,
              mrn: consult.patientMrn,
              nhs: getNhsNumberFromPatient(consult.patient)
            },
            clinic: consult.clinic
          };

          return result;
        }
      );
      this.perPage = response.meta.per_page;
      this.total = response.meta.total;
      this.filtered = this.search.length > 0;

      this.progressStore.finishProgress();
      this.loading = false;
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.progressStore.setError();
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.consult.fetch-error')
        });
      }
      if (!axios.isAxiosError(err)) {
        throw err;
      }
    }
  }

  async updateSearch() {
    // Maintain sort order and only add search param when non-empty
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...(this.$route.query.sort ? { sort: this.$route.query.sort } : {}),
        ...(this.search ? { 'filter[search]': this.search } : {})
      }
    });
  }

  get updateSearchDebounced() {
    return debounce(() => this.updateSearch(), 500);
  }

  async changePage(page: number) {
    // Keep all existing query parameters
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...this.$route.query,
        page: page.toString()
      }
    });
  }

  async updateSort(sort: string) {
    // Keep the search if present, but always reset the page
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...(this.search ? { search: this.search } : {}),
        sort
      }
    });
  }

  formatDate(date: string) {
    return this.$d(new Date(date), 'shortMonth');
  }

  formatDob(dob: string) {
    return this.$d(getDobFromISOString(dob), 'shortMonth');
  }

  getSexOrGender(patient: Patient): string | TranslateResult | undefined {
    if (this.sessionStore.currentOrganisation?.uses_gender && patient.gender) {
      const gender = this.genderOptions.find(
        (option) => option.value === patient.gender
      );
      return gender ? gender.label : patient.gender;
    }
    const sex = this.sexOptions.find((option) => option.value === patient.sex);
    return sex ? sex.label : patient.sex;
  }

  showAssignConsultModal(consult: Partial<Consult>) {
    this.selectedConsultId = consult.id;
    this.currentConsult = consult;
    this.selectedPatient = consult.patient ? consult.patient : null;
    this.enableAssignConsultModal = true;
  }

  closeAssignConsultModal() {
    this.enableAssignConsultModal = false;
    this.selectedConsultId = '';
    this.fetchConsults();
  }

  async schedule(scheduleDetails: ScheduleValue) {
    const scheduledStatus = findCodeableConceptByCode('triaged', this.statuses);
    try {
      const data = {
        status_id: scheduledStatus?.id ?? '',
        scheduled_at: scheduleDetails.scheduled_at,
        scheduled_location: scheduleDetails.scheduled_location,
        ...(scheduleDetails.patient_mrn_at_clinic_provider && {
          patient_mrn_at_clinic_provider:
          scheduleDetails.patient_mrn_at_clinic_provider
        })
      };

      const currentEpisodeOfCare = this.extractEpisodeOfCareFromConsult(
        this.currentConsult?.id
      );

      if (currentEpisodeOfCare?.encounters[0]) {
        await this.encounterService.update(
          currentEpisodeOfCare.encounters[0].id,
          data
        );
      }
      this.closeAssignConsultModal();
      this.fetchConsults();
    } catch (e) {
      if (e.response?.status === 422) {
        this.scheduleErrors = e.response.data?.errors;
      } else {
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.consult.update-error')
        });
      }
    }
  }

  // Reject consult
  openRejectReasonModal(consult: Consult) {
    this.selectedConsultId = consult.id;
    this.currentConsult = consult;
    this.selectedPatient = consult.patient ? consult.patient : null;
    this.enableRejectReasonModal = true;
  }

  async markAsRejected(rejectedReason: RejectReasonValue) {
    const rejectedStatus = findCodeableConceptByCode(
      'cancelled',
      this.statuses
    );
    try {
      const data = {
        status_id: rejectedStatus?.id ?? '',
        extension: {
          rejected: {
            type: rejectedReason.selectedReason,
            reason: rejectedReason.additionalReason
          }
        }
      };

      const currentEpisodeOfCare = this.extractEpisodeOfCareFromConsult(
        this.currentConsult?.id
      );

      if (currentEpisodeOfCare?.encounters[0]) {
        await this.encounterService.update(
          currentEpisodeOfCare.encounters[0].id,
          data
        );
      }
      this.closeRejectReasonModal();
      await this.fetchConsults();
    } catch (e) {
      if (e.response?.status === 422) {
        this.rejectReasonError = e.response.data?.message ?? '';
      } else {
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.consult.update-error')
        });
      }
    }
  }

  closeRejectReasonModal() {
    this.selectedConsultId = '';
    this.currentConsult = null;
    this.selectedPatient = null;
    this.enableRejectReasonModal = false;
    this.fetchConsults();
  }

  updateRejectErrorMessage(value: string) {
    this.rejectReasonError = value;
  }

  extractRejectedErrorMessageFromResponseErrors(
    errors: IErrors
  ): string | null {
    let errorMessages: Array<string> = [];
    if (errors.rejected_reason) {
      errorMessages = errorMessages.concat(errors.rejected_reason);
    }
    if (errors.rejected_reason_type) {
      errorMessages = errorMessages.concat(errors.rejected_reason_type);
    }
    if (errorMessages.length === 0) {
      return null;
    }
    return errorMessages.join(' ');
  }
}
