import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "my-12" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "flex justify-between -mt-4 border-b" }
const _hoisted_4 = { class: "flex items-center text-lg font-bold text-gray-900" }
const _hoisted_5 = { class: "flex py-4" }
const _hoisted_6 = { class: "font-medium text-gray-900" }
const _hoisted_7 = { class: "uppercase" }
const _hoisted_8 = { class: "font-medium text-gray-900" }
const _hoisted_9 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_10 = {
  key: 0,
  class: "text-sm font-medium capitalize text-gray-500"
}
const _hoisted_11 = {
  key: 1,
  class: "text-sm font-medium capitalize text-gray-500 flex items-center"
}
const _hoisted_12 = { class: "uppercase text-gray-600 ml-1" }
const _hoisted_13 = { class: "font-medium text-gray-900" }
const _hoisted_14 = { class: "text-sm font-medium capitalize text-gray-500" }
const _hoisted_15 = { class: "font-medium text-gray-900" }
const _hoisted_16 = { class: "flex flex-row items-center justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_MkBadge = _resolveComponent("MkBadge")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_SpecialityButton = _resolveComponent("SpecialityButton")!
  const _component_PopoverLink = _resolveComponent("PopoverLink")!
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_BasePopover = _resolveComponent("BasePopover")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_BasePagination = _resolveComponent("BasePagination")!
  const _component_AssignConsultModal = _resolveComponent("AssignConsultModal")!
  const _component_RejectReasonModal = _resolveComponent("RejectReasonModal")!
  const _directive_allow = _resolveDirective("allow")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.filtered
              ? _ctx.$t('platform.common.search-results')
              : _ctx.$t('custom.uhb.worklist.patient-scheduling')), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_BaseTextInput, {
            modelValue: _ctx.search,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            placeholder: _ctx.$t('platform.common.search'),
            size: "small",
            onKeyup: _ctx.updateSearchDebounced
          }, null, 8, ["modelValue", "placeholder", "onKeyup"])
        ])
      ]),
      _createVNode(_component_DataTable, {
        loading: _ctx.loading,
        columns: [
          { name: 'last_name', label: _ctx.$t('platform.patient.patient') },
          { name: 'date_of_birth', label: _ctx.$t('platform.patient.born') },
          { name: 'patient_ids' },
          { name: 'clinic_name', label: _ctx.$t('custom.uhb.consult.clinic') },
          { name: 'date_added', label: _ctx.$t('custom.uhb.worklist.date-added') },
          {
            name: 'consult_type',
            label: _ctx.$t('custom.uhb.consult.consult-type')
          },
          { name: 'actions', label: '', align: 'right' }
        ],
        rows: _ctx.rows,
        sort: _ctx.sort,
        class: "w-full mt-6",
        onSort: _ctx.updateSort
      }, {
        "cell.last_name": _withCtx(({ row }) => [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(row.patient.last_name) + ",", 1),
            _createTextVNode(" " + _toDisplayString(row.patient.first_name), 1)
          ])
        ]),
        "cell.date_of_birth": _withCtx(({ row }) => [
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.formatDob(row.patient.date_of_birth)), 1),
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.getSexOrGender(row.patient)) + " (" + _toDisplayString(_ctx.$t('platform.patient.years', {age: row.patient.age})) + ") ", 1)
        ]),
        "cell.patient_ids": _withCtx(({ row }) => [
          (row.patient.mrn)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t('platform.patient.mrn-abbreviation')) + " : " + _toDisplayString(row.patient.mrn), 1))
            : _createCommentVNode("", true),
          (row.patient.nhs)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createVNode(_component_BaseIcon, { name: "nhs-logo" }),
                _createElementVNode("div", _hoisted_12, " : " + _toDisplayString(row.patient.nhs), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        "cell.clinic_name": _withCtx(({ row }) => [
          _createElementVNode("div", _hoisted_13, _toDisplayString(row.clinic.name), 1),
          _createElementVNode("div", _hoisted_14, _toDisplayString(row.clinic.provider ? row.clinic.provider.name : ''), 1)
        ]),
        "cell.date_added": _withCtx(({ row }) => [
          _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.formatDate(row.created_at)), 1)
        ]),
        "cell.consult_type": _withCtx(({ row }) => [
          (row.consult_type && row.consult_type.name === 'New Patient')
            ? (_openBlock(), _createBlock(_component_MkBadge, {
                key: 0,
                class: "w-full",
                label: _ctx.$t('custom.uhb.worklist.new-patient'),
                color: "primary",
                stroked: ""
              }, null, 8, ["label"]))
            : _createCommentVNode("", true),
          (
              row.consult_type && row.consult_type.name === 'Follow-Up Patient'
            )
            ? (_openBlock(), _createBlock(_component_MkBadge, {
                key: 1,
                class: "w-full",
                label: _ctx.$t('custom.uhb.worklist.follow-up'),
                color: "success",
                stroked: ""
              }, null, 8, ["label"]))
            : _createCommentVNode("", true)
        ]),
        "cell.actions": _withCtx(({ row }) => [
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_component_BaseButton, {
              "right-icon": "chevron-right",
              color: "ghost",
              onClick: ($event: any) => (_ctx.showAssignConsultModal(row))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('custom.uhb.worklist.schedule')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_BasePopover, { position: "left" }, {
              button: _withCtx(() => [
                _createVNode(_component_SpecialityButton, { class: "ml-3" })
              ]),
              menu: _withCtx(() => [
                _createVNode(_component_PopoverLink, {
                  to: {
                    name: 'patient',
                    params: {
                      organisationId: _ctx.$route.params.organisationId,
                      patientId: row.patient.id
                    }
                  }
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('platform.patient.view')), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]),
                (
                    [
                      'Needs Scheduling',
                      'Awaiting Consult',
                      'Consulting'
                    ].includes(row.status)
                  )
                  ? _withDirectives((_openBlock(), _createBlock(_component_PopoverButton, {
                      key: 0,
                      onClick: ($event: any) => (_ctx.openRejectReasonModal(row))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('custom.uhb.consult.reject-for-distributed-clinic')), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])), [
                      [_directive_allow, 'consult:reject']
                    ])
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)
          ])
        ]),
        _: 1
      }, 8, ["loading", "columns", "rows", "sort", "onSort"]),
      (_ctx.total && _ctx.total > _ctx.perPage)
        ? (_openBlock(), _createBlock(_component_BasePagination, {
            key: 0,
            "model-value": _ctx.page,
            "page-size": _ctx.perPage,
            length: _ctx.total,
            "sm-pages-limit": 5,
            "md-pages-limit": 8,
            "items-name": _ctx.$t('platform.navigation.patients').toLowerCase(),
            "onUpdate:modelValue": _ctx.changePage
          }, null, 8, ["model-value", "page-size", "length", "items-name", "onUpdate:modelValue"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.enableAssignConsultModal)
      ? (_openBlock(), _createBlock(_component_AssignConsultModal, {
          key: 0,
          title: _ctx.$t('custom.uhb.consult.schedule-consult'),
          patient: _ctx.selectedPatient,
          "episode-of-care": 
        _ctx.currentConsult?.id &&
          _ctx.extractEpisodeOfCareFromConsult(_ctx.currentConsult?.id)
      ,
          "can-edit-patient-mrn": !_ctx.currentConsult?.patient_mrn_at_clinic_provider,
          "clinic-type-options": 
        _ctx.currentConsult?.clinic && _ctx.buildClinicTypeOptions(_ctx.currentConsult?.clinic)
      ,
          errors: _ctx.scheduleErrors,
          onClose: _ctx.closeAssignConsultModal,
          onUpdatePatient: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update-patient'))),
          onSchedule: _ctx.schedule
        }, null, 8, ["title", "patient", "episode-of-care", "can-edit-patient-mrn", "clinic-type-options", "errors", "onClose", "onSchedule"]))
      : _createCommentVNode("", true),
    (_ctx.enableRejectReasonModal)
      ? (_openBlock(), _createBlock(_component_RejectReasonModal, {
          key: 1,
          title: _ctx.$t('custom.uhb.consult.schedule-consult'),
          patient: _ctx.selectedPatient,
          "episode-of-care": 
        _ctx.currentConsult?.id &&
          _ctx.extractEpisodeOfCareFromConsult(_ctx.currentConsult?.id)
      ,
          error: _ctx.rejectReasonError,
          onUpdateErrorMessage: _ctx.updateRejectErrorMessage,
          onMarkAsRejected: _ctx.markAsRejected,
          onClose: _ctx.closeRejectReasonModal
        }, null, 8, ["title", "patient", "episode-of-care", "error", "onUpdateErrorMessage", "onMarkAsRejected", "onClose"]))
      : _createCommentVNode("", true)
  ]))
}